.react-datepicker {
  background: white;
}

.custom-calendar {
  background: white;
  border: none;
}

/* .inputCalendar {
  background: white;
  border: none;
} */

.react-datepicker__header {
  background: white;
  border: none;
}

.react-datepicker__month {
  background: white;
}

.react-datepicker__tab-loop {
  position: absolute;
  top: 0;
}

/* .react-datepicker__triangle {
  display: none !important;
} */

.react-datepicker__time-container
  .react-datepicker__time
  .react-datepicker__time-caption {
  display: none;
}

.react-datepicker__time-container {
  .react-datepicker__header--time {
    padding: 3px;
    background-color: white;
    border: none;
  }
}

.react-datepicker-popper[data-placement^='bottom']
  .react-datepicker__triangle::after {
  border-bottom-color: white;
}
